<div class="spinner-container" *ngIf="isLoading">
  <mat-spinner diameter="50"></mat-spinner>
</div>

<div *ngIf="!isLoading" class="flex items-end justify-end mb-5">
  <button
    mat-raised-button
    class="text-primary back-btn"
    (click)="goToLanding()"
  >
    <mat-icon class="mat-18"
      ><span class="material-symbols-outlined"> arrow_back </span></mat-icon
    >Go back to EMB portal 
  </button>
</div>

<div *ngIf="!isLoading" class="flex flex-row rounded-md border border-grey-500">
  
  <div class="basis-2/3 p-10 text-primary">
    <h1 class="text-3xl mb-7 font-bold">Login</h1>

    <form [formGroup]="loginForm">
      <!--Email-->
      <div class="text-base mb-3 lg:text-lg font-medium">
        Email Address <sup>*</sup>
      </div>
      <mat-form-field class="w-full mb-2" appearance="outline">
        <input matInput type="email" placeholder="Please enter your email address" formControlName="email" />
        <mat-error
          *ngIf="this.loginForm.get('email')?.touched && this.loginForm.get('email')?.hasError('required')">
          Email is required</mat-error>
        <mat-error *ngIf="this.loginForm.get('email')?.touched && this.loginForm.get('email')?.hasError('email')">
          Email must be a valid Email</mat-error>
      </mat-form-field>

      <!--Password-->
      <div class="text-base mb-3 lg:text-lg font-medium">
        Password <sup>*</sup>
      </div>
      <mat-form-field class="w-full" appearance="outline">
        <input matInput [type]="showPassword ? 'text' : 'password'" placeholder="Please enter the password" formControlName="password" />
        <mat-icon matSuffix (click)="togglePasswordVisibility()">
          {{showPassword?'visibility_off':'visibility'}}
        </mat-icon>
        <mat-error
          *ngIf="this.loginForm.get('password')?.touched && this.loginForm.get('password')?.hasError('required')">
          Password is required</mat-error>
      </mat-form-field>

      <div class="error-container" *ngIf="errorMessage">
        <span>{{ errorMessage }}</span>
        <button mat-icon-button (click)="clearError()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="flex justify-end underline pointer" routerLink="/forgot-password">Forgot password?</div>

      <div class="button flex justify-end mt-4">
        <button type="submit" mat-raised-button class="mat-blue" [disabled]="!loginForm.valid || disableButton" (click)="onSubmit()">Login</button>
      </div>

    </form>

    <app-notice></app-notice>

  </div>

  <div class="basis-1/3 bg-lightBlue text-primary">
    <div class="flex justify-center pt-40 font-bold text-2xl">Welcome Back!</div>
    <div class="flex flex-col items-center pt-40 gap-5 font-bold text-xl">
      <div>Don’t have an account?</div>
      <div>
        <button type="submit" mat-raised-button class="mat-blue" (click)="gotoSignUpPage()">Sign Up</button>
      </div>
    </div>
  </div>
</div>